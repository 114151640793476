const USER_REQUEST_OPTIONS = {
  requestType: 'no-cors',
};

const ERRORS = {
  default: {
    status: 500,
    title: 'Oh Snap!',
    message: 'Something went wrong.',
    name: 'error',
  },
  '400': {
    status: 400,
    title: 'Oh Snap!',
    message: 'Bad Request.',
    name: 'warning',
  },
  '401': {
    status: 401,
    title: 'Unauthorized',
    message: "You don't have permission to access #{app.name}.",
    name: 'no-access',
  },
  '403': {
    status: 403,
    title: 'Access Denied',
    message: "You don't have permission to access #{app.name}.",
    name: 'no-access',
  },
  '404': {
    status: 404,
    title: 'Nothing to see Here',
    message: 'Page not found.',
    name: '404',
  },
  '412': {
    status: 412,
    title: 'Oh Snap!',
    message: 'Invalid configuration.',
    name: 'warning',
  },
  '500': {
    status: 500,
    title: 'Oh Snap!',
    message: 'Something went wrong.',
    name: 'error',
  },
  '503': {
    status: 503,
    title: 'Oh Snap!',
    message: 'Something went wrong.',
    name: 'server-down',
  },
  access_denied: {
    status: 403,
    title: 'Access Denied',
    message: "You don't have permission to access #{app.name}.",
    name: 'no-access',
  },
  unauthorized_client: {
    status: 400,
    title: 'Oh Snap!',
    message:
      'The client is not authorized to request an authorization code using this method.',
    name: 'warning',
  },
  unsupported_response_type: {
    status: 400,
    title: 'Oh Snap!',
    message:
      'The authorization server does not support obtaining an authorization code using this method.',
    name: 'warning',
  },
  unsupported_response_mode: {
    status: 400,
    title: 'Oh Snap!',
    message:
      'The authorization server does not support the requested response mode.',
    name: 'warning',
  },
  invalid_scope: {
    status: 400,
    title: 'Oh Snap!',
    message: 'The requested scope is invalid, unknown, or malformed.',
    name: 'warning',
  },
  server_error: {
    status: 503,
    title: 'Oh Snap!',
    message:
      'The authorization server encountered an unexpected condition that prevented it from fulfilling the request.',
    name: 'server-down',
  },
  temporarily_unavailable: {
    status: 503,
    title: 'Oh Snap!',
    message:
      'The authorization server is currently unable to handle the request due to a temporary overloading or maintenance of the server.',
    name: 'server-down',
  },
  invalid_client: {
    status: 400,
    title: 'Oh Snap!',
    message: 'The specified client is not valid.',
    name: 'warning',
  },
  login_required: {
    status: 401,
    title: 'Oh Snap!',
    message:
      'The client specified not to prompt, but the user is not logged in.',
    name: 'no-access',
  },
  invalid_request: {
    status: 400,
    title: 'Oh Snap!',
    message: 'The request parameters are not valid.',
    name: 'warning',
  },
  user_canceled_request: {
    status: 400,
    title: 'Oh Snap!',
    message: 'User canceled the social login request.',
    name: 'warning',
  },
} as const;

export { ERRORS, USER_REQUEST_OPTIONS };
