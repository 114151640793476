import React from 'react';
import {
  Illustration,
  PageWrapper,
  Grid,
  Typography,
  Textblock,
} from '@screentone/core';

import ErrorMessage from '../../components/ErrorMessage';
import RequestAccess from '../../components/RequestAccess';
import { AppConfig } from '../../types';
import { ERRORS } from './Error.constants'; //  USER_REQUEST_OPTIONS

import './Error.styles.css';

interface ErrorPageProps {
/**
* Type.
* [See App Props page for details](./?path=/docs/authrouter-app-props--page)'
*/
  type: keyof typeof ERRORS;
  /**
  * Base details of your app, like your App name.
  * [See App Props page for details](./?path=/docs/authrouter-app-props--page)'
  */
  app?: AppConfig;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ type, app }) => {
  const error = ERRORS[type] || ERRORS.default;
  let requestAccess = null;
  let RequestAccessOverride = null;

  if (error.status === 403 && app && React.isValidElement(app.RequestAccessOverride)) {
    RequestAccessOverride = app.RequestAccessOverride;
  }

  if (error.status === 403 && app && app.requestAccess) {
    requestAccess = Object.assign(
      {},
      {
        ...app.requestAccess,
        properties: app.properties,
        appName: app.name,
        appEnv: app.env,
      }
    );
  }

  return (
    <PageWrapper>
      <Grid>
        <Grid.Row>
          <Grid.Col xs={12} md={requestAccess ? 6 : 12}>
            <Illustration
              name={error.name}
              className="st-addon-okta-error-page-illustration"
            />
            <Typography
              componentEl="h1"
              size="xxl"
              className="st-addon-okta-error-page-text"
            >
              {error.title}
            </Typography>
            <Textblock className="st-addon-okta-error-page-text">
              <ErrorMessage message={error.message} app={app} />
            </Textblock>
          </Grid.Col>
          {RequestAccessOverride ? RequestAccessOverride : requestAccess ? <RequestAccess {...requestAccess} /> : null }
        </Grid.Row>
      </Grid>
    </PageWrapper>
  );
};

export default ErrorPage;
export { ErrorPage, ErrorPageProps };
